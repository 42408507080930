
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { EditArticle } from '@/mixins/EditArticle';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import headersDataTable from './headersDataTable';
import FormatPriceInObject from '@/mixins/FormatPriceInObject';
import i18n from '@/i18n';

@Component
export default class OptionArticleDataTable extends mixins(EditArticle, FormatPriceInObject) {
  @Prop({ type: Array, required: true }) public items!: OptionArticle[];

  public editDialog: any = {
    isVisible: false,
    value: null,
    name: '',
    original: null,
  };

  public expanded: string[] = [];
  public headers: ColumnHeader[] = headersDataTable;
  get tableHeaders() {
    return this.headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  public handleCellClick(header: ColumnHeader, item: any) {
    if (!header.editable) {
      return;
    }
    this.editDialog.isVisible = true;
    this.editDialog.name = header.value;
    if (this.editDialog.name === 'name' || this.editDialog.name === 'description') {
      this.editDialog.value = this.$options.filters!.localized(item[header.value]);
    } else {
      this.editDialog.value = item[header.value].$numberDecimal
        ? item[header.value].$numberDecimal
        : item[header.value];
    }
    this.editDialog.original = { ...item };
  }

  public saveCellValue() {
    const data = {
      original: this.editDialog.original,
      field: this.editDialog.name,
      value: this.editDialog.value,
    };
    if (this.editDialog.name === 'price') {
      // TODO - may mutate unexpected fields
      const arr = ['value'];
      arr.map((type: any) => {
        // mutate merged data
        const res = this.deepSearchItems(data, type, (k: any, v: any) => {
          if (v && v.includes) {
            return v.includes(',');
          }
        });
        res.map((obj: any) => {
          obj[type] = obj[type].replaceAll(',', '.');
        });
      });
    } else if (this.editDialog.name === 'name' || this.editDialog.name === 'description') {
      const original = { ...this.editDialog.original[this.editDialog.name] };
      original[i18n.locale] = this.editDialog.value;
      data.value = original;
    }
    this.$emit('edited', data);
    this.editDialog.isVisible = false;
  }

  public stopEditing() {
    this.editDialog.isVisible = false;
    this.editDialog.value = null;
    this.editDialog.original = null;
    this.editDialog.name = '';
  }
}
