export default [
  {
    text: 'option.articles.table.name',
    value: 'name',
    default: true,
    editable: true,
  },
  {
    text: 'article.table.externalId',
    value: 'externalId',
    default: true,
    editable: true,
  },
  {
    text: 'option.articles.table.internalNote',
    value: 'internalNote',
    default: true,
  },
  {
    text: 'option.articles.table.number',
    value: 'optionNumber',
    default: true,
    editable: true,
  },
  {
    text: 'option.articles.table.price',
    value: 'price',
    type: 'decimal',
    default: true,
    editable: true,
  },
  {
    text: 'option.articles.table.sortOrder',
    value: 'sortOrder',
    default: false,
    editable: true,
  },
  {
    text: 'option.articles.table.visible',
    value: 'visible',
    type: 'slot',
    default: true,
  },
  {
    text: 'option.articles.table.active',
    value: 'isActive',
    type: 'slot',
    default: true,
  },
  {
    text: '',
    value: 'actions',
  },
  { text: '', value: 'data-table-expand' },
];
